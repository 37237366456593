<template>
  <div>
    <AddNewRoom
      v-model="isAddNewRoom"
      @onAdd="fetchDataTable"
    />
    <EditRoom
      v-model="isEditRoom"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <v-card>
      <v-card-title class="px-2">
        {{ $t('examination_room') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewRoom =true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('add_examination_room') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          outlined
          icon
          @click="isAddNewRoom =true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            :placeholder="$t('wordingSearch')"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
          class="py-0"
        >
          <v-select
            v-model="room_status_id"
            :placeholder="$t('status')"
            :label="$t('status')"
            :items="statusList"
            :item-text="$i18n.locale"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableColumns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalDataTableList"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.room_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.room_status_id`]="{ item }">
          <StatusBlock :status="item.room_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item, isEditRoom = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="room_id = item.room_id,room_status_id_delete = item.room_status_id,isDeleteRoom = true"
                v-on="on"
              >
                <v-icon>{{ item.room_status_id==1?icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.room_status_id==1?$t("suspend"):$t('normal') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="isDeleteRoom"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="me-1"
            color="warning"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ room_status_id_delete=='1'? $t('confirm_room_suspend') :$t('cancel_room_suspend') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processDelete"
            :disabled="processDelete"
            @click="handleUpdataStatusRoom"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isDeleteRoom = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiAlertOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useExaminationRoom from './useExaminationRoom'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewRoom from './AddNewRoom.vue'
import EditRoom from './EditRoom.vue'

export default {
  components: {
    StatusBlock, AddNewRoom, EditRoom,
  },
  setup() {
    const isAddNewRoom = ref(false)
    const isEditRoom = ref(false)
    const dataEdit = ref({})

    const {
      processDelete,
      room_status_id_delete,
      isDeleteRoom,
      handleUpdataStatusRoom,
      room_id, dataTableList, totalDataTableList, tableColumns, segmentId, options, footer, statusList, loading, searchtext, room_status_id, fetchDataTable,
    } = useExaminationRoom()

    return {
      isDeleteRoom,
      room_status_id_delete,
      processDelete,
      room_id,
      isAddNewRoom,
      dataTableList,
      totalDataTableList,
      tableColumns,
      segmentId,
      options,
      footer,
      loading,
      searchtext,
      statusList,
      room_status_id,
      dataEdit,
      fetchDataTable,
      handleUpdataStatusRoom,
      isEditRoom,
      icons: {
        mdiAlertOutline,
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiCheck,
      },
    }
  },

}
</script>
