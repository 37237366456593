<template>
  <div>
    <v-dialog v-model="isEditRoom" persistent max-width="500">
      <v-card>
        <v-card-title>
          {{ $t('edit_examination_room') }}
        </v-card-title>
        <v-form ref="formEditRoom" @submit.prevent="updateRoom">
          <v-card-text>
            <v-text-field v-model="shop_name" dense readonly :label="$t('shop_name')" outlined></v-text-field>
            <v-text-field
              v-model="dataEditLocal.room_name"
              dense
              :rules="[required]"
              :label="$t('examination_room')"
              outlined
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">
              {{ $t('save') }}
            </v-btn>
            <v-btn color="secondary" outlined @click="$emit('update:is-edit-room', false)">
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import examinationRoom from '@/api/systemSetting/examinationRoom'
import store from '@/store'

export default {
  model: {
    prop: 'isEditRoom',
    event: 'update:is-edit-room',
  },
  props: {
    isEditRoom: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const dataEditLocal = ref({})
    const formEditRoom = ref(null)
    const room_name = ref('')
    const shop_name = localStorage.getItem('shop_name')
    const loading = ref(false)

    const { roomUpdate } = examinationRoom
    const updateRoom = () => {
      const isFormValid = formEditRoom.value.validate()
      if (!isFormValid) return
      loading.value = true
      roomUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        formEditRoom.value.reset()
        emit('update:is-edit-room', false)
        emit('onUpdate', res.response)
      })
    }
    watch(
      () => props.dataEdit,
      () => {
        dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
      },
    )

    return {
      shop_name,
      room_name,
      required,
      formEditRoom,
      updateRoom,
      loading,
      dataEditLocal,
    }
  },
}
</script>
