<template>
  <div>
    <v-dialog
      v-model="isAddNewRoom"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('add_examination_room') }}
        </v-card-title>
        <v-form
          ref="formAddRoom"
          @submit.prevent="createNewRoom"
        >
          <v-card-text>
            <v-text-field
              v-model="shop_name"
              dense
              readonly
              :label="$t('shop_name')"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="room_name"
              dense
              :rules="[required]"
              :label="$t('examination_room')"
              outlined
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-room', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import examinationRoom from '@/api/systemSetting/examinationRoom'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewRoom',
    event: 'update:is-add-new-room',
  },
  props: {
    isAddNewRoom: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formAddRoom = ref(null)
    const room_name = ref('')
    const shop_name = localStorage.getItem('shop_name')
    const loading = ref(false)

    const message = ref('')
    const colorStatus = ref('')
    const statusShowAlert = ref(false)

    const { addNewRoom } = examinationRoom
    const createNewRoom = () => {
      const isFormValid = formAddRoom.value.validate()
      if (!isFormValid) return
      loading.value = true
      addNewRoom({
        room_name: room_name.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        formAddRoom.value.reset()
        emit('update:is-add-new-room', false)
        emit('onAdd', res.response)
      })
    }
    watch(() => props.isAddNewRoom, newValue => {
      if (newValue) {
        room_name.value = ''
      }
    })

    return {
      shop_name, room_name, required, formAddRoom, createNewRoom, loading, message, colorStatus, statusShowAlert,
    }
  },

}
</script>
