import { onMounted, ref, watch } from '@vue/composition-api'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import examintionRoom from '@/api/systemSetting/examinationRoom'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default function useExaminationRoom() {
  // filters valiable
  const searchtext = ref('')
  const statusList = ref(basicStatus.data)
  const room_status_id = ref(1)
  const room_status_id_delete = ref(1)

  const isDeleteRoom = ref(false)
  const room_id = ref('')
  const processDelete = ref(false)

  // datatable valiable
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const segmentId = ref(0)
  const options = ref({})
  const loading = ref(false)
  const tableColumns = [
    { text: '#', align: 'start', value: 'room_id' },
    { text: i18n.t('examination_room'), value: 'room_name' },
    { text: i18n.t('status'), value: 'room_status_id' },
    { text: i18n.t('option'), value: 'actions', align: 'center' },
  ]
  const { examinationRoomList, roomStatusUpdate } = examintionRoom

  onMounted(() => {
    loading.value = true
    fetchDataTable()
  })
  const fetchDataTable = () => {
    loading.value = true
    const body = {
      searchtext: searchtext.value,
      room_status_id: room_status_id.value,
    }
    examinationRoomList(body).then(res => {
      const {
        data, count,
      } = res
      dataTableList.value = data
      totalDataTableList.value = count
      options.value.itemsPerPage = -1
      loading.value = false
    })
  }

  const handleUpdataStatusRoom = () => {
    processDelete.value = true
    const body = {
      room_id: room_id.value,
      room_status_id: room_status_id_delete.value == 1 ? 2 : 1,
    }
    roomStatusUpdate(body).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      processDelete.value = false
      fetchDataTable()
      isDeleteRoom.value = false
    })
  }

  watch([searchtext, room_status_id], (newvalue, oldvalue) => {
    if (newvalue !== oldvalue) {
      loading.value = true
      fetchDataTable()
    }
  })

  return {
    room_status_id_delete,
    processDelete,
    isDeleteRoom,
    room_id,
    dataTableList,
    totalDataTableList,
    tableColumns,
    segmentId,
    options,
    footer,
    statusList,
    loading,
    searchtext,
    room_status_id,
    handleUpdataStatusRoom,
    fetchDataTable,
  }
}
